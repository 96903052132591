var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.proRecipe && _vm.isPublishPeriod(_vm.proRecipe)
    ? _c(
        "div",
        { staticClass: "dp-pro-recipe-brewing" },
        [
          _vm.isLoading
            ? _c("DpPageLoading", { attrs: { "is-loading": _vm.isLoading } })
            : _vm._e(),
          _c(
            "div",
            {
              style: {
                opacity: !_vm.isLoading ? 1 : 0,
                paddingTop: _vm.isAndroid ? "36px" : "",
              },
            },
            [
              _c("div", { staticClass: "dp-pro-recipe-brewing-head" }, [
                _vm.image
                  ? _c(
                      "div",
                      { staticClass: "dp-pro-recipe-brewing-head__image" },
                      [
                        _c("img", {
                          attrs: { src: _vm.image, alt: "" },
                          on: { load: _vm.onImgLoaded },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm.proRecipe.name
                  ? _c(
                      "h2",
                      { staticClass: "dp-pro-recipe-brewing-head__title" },
                      [_vm._v(" " + _vm._s(_vm.proRecipe.name) + " ")]
                    )
                  : _vm._e(),
              ]),
              _vm.proRecipe.recipe && _vm.proRecipe.recipe.length > 0
                ? _c(
                    "DpSection",
                    {
                      attrs: {
                        "title-en": _vm.WORDS.DRIP_POD_PRO_RECIPE.RECIPE,
                      },
                    },
                    [
                      _c(
                        "DpSectionInner",
                        [
                          _c("DpProRecipeMaterialList", {
                            attrs: { contents: _vm.proRecipe.recipe },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.proRecipe.process &&
              _vm.proRecipe.process.length > 0 &&
              _vm.proRecipe.process[0]
                ? _c(
                    "DpSection",
                    {
                      attrs: {
                        "title-en": _vm.WORDS.DRIP_POD_PRO_RECIPE.STEPS,
                      },
                    },
                    [
                      _c(
                        "DpSectionInner",
                        [
                          _c("DpProRecipeStepList", {
                            attrs: { contents: _vm.proRecipe.process },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.proRecipe.capsule &&
              _vm.isPublishPeriod(_vm.proRecipe.capsule)
                ? _c(
                    "DpSection",
                    {
                      attrs: {
                        "title-en": _vm.WORDS.DRIP_POD_PRO_RECIPE.TASTE,
                      },
                    },
                    [
                      _c(
                        "DpSectionInner",
                        [
                          _c("DpProRecipeTaste", {
                            attrs: { contents: _vm.proRecipe },
                          }),
                        ],
                        1
                      ),
                      _vm.proRecipe.barista &&
                      _vm.isPublishPeriod(_vm.proRecipe.barista) &&
                      _vm.proInformation
                        ? _c(
                            "DpSectionInner",
                            [
                              _vm.proRecipe.message
                                ? _c("DpProRecipeBalloon", {
                                    attrs: {
                                      title:
                                        _vm.WORDS.DRIP_POD_PRO_RECIPE.POINT,
                                      text: _vm.proRecipe.message,
                                    },
                                  })
                                : _vm._e(),
                              _c("DpProRecipeProInformation", {
                                attrs: { contents: _vm.proInformation },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.isExtracted
            ? _c(
                "a",
                {
                  staticClass: "dp-pro-recipe-brewing-link",
                  attrs: { href: "javascript:void(0)" },
                  on: { click: _vm.onClickLink },
                },
                [
                  _c(
                    "span",
                    { staticClass: "dp-pro-recipe-brewing-link__text" },
                    [_vm._v("レシピ詳細へ")]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div
    v-if="proRecipe && isPublishPeriod(proRecipe)"
    class="dp-pro-recipe-brewing">
    <DpPageLoading v-if="isLoading" :is-loading="isLoading" />

    <div
      :style="{
        opacity: !isLoading ? 1 : 0,
        paddingTop: isAndroid ? '36px' : ''
      }">
      <div class="dp-pro-recipe-brewing-head">
        <div v-if="image" class="dp-pro-recipe-brewing-head__image">
          <img :src="image" alt="" @load="onImgLoaded" />
        </div>

        <h2 v-if="proRecipe.name" class="dp-pro-recipe-brewing-head__title">
          {{ proRecipe.name }}
        </h2>
      </div>

      <DpSection
        v-if="proRecipe.recipe && proRecipe.recipe.length > 0"
        :title-en="WORDS.DRIP_POD_PRO_RECIPE.RECIPE">
        <DpSectionInner>
          <DpProRecipeMaterialList :contents="proRecipe.recipe" />
        </DpSectionInner>
      </DpSection>

      <DpSection
        v-if="
          proRecipe.process &&
          proRecipe.process.length > 0 &&
          proRecipe.process[0]
        "
        :title-en="WORDS.DRIP_POD_PRO_RECIPE.STEPS">
        <DpSectionInner>
          <DpProRecipeStepList :contents="proRecipe.process" />
        </DpSectionInner>
      </DpSection>

      <DpSection
        v-if="proRecipe.capsule && isPublishPeriod(proRecipe.capsule)"
        :title-en="WORDS.DRIP_POD_PRO_RECIPE.TASTE">
        <DpSectionInner>
          <DpProRecipeTaste :contents="proRecipe" />
        </DpSectionInner>

        <DpSectionInner
          v-if="
            proRecipe.barista &&
            isPublishPeriod(proRecipe.barista) &&
            proInformation
          ">
          <DpProRecipeBalloon
            v-if="proRecipe.message"
            :title="WORDS.DRIP_POD_PRO_RECIPE.POINT"
            :text="proRecipe.message" />

          <DpProRecipeProInformation :contents="proInformation" />
        </DpSectionInner>
      </DpSection>
    </div>
    <a
      v-if="isExtracted"
      href="javascript:void(0)"
      @click="onClickLink"
      class="dp-pro-recipe-brewing-link">
      <span class="dp-pro-recipe-brewing-link__text">レシピ詳細へ</span>
    </a>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { SystemDialogMessage } from '@/constants/enums';
import {
  defineComponent,
  ref,
  computed,
  watch,
  inject
} from '@vue/composition-api';
import { usePublishable } from '@/composables/usePublishable';
import { useUserAgent } from '@/composables/useUserAgent';
import { useNativeConnection } from '@/composables/useNativeConnection';
const { setHideBrewingView } = useNativeConnection();

export default defineComponent({
  components: {
    DpPageLoading: () => import('@/components/dripPod/core/PageLoading.vue'),
    DpSection: () => import('@/components/dripPod/core/Section.vue'),
    DpSectionInner: () => import('@/components/dripPod/core/SectionInner.vue'),

    DpProRecipeMaterialList: () =>
      import('@/components/dripPod/proRecipe/MaterialList.vue'),

    DpProRecipeStepList: () =>
      import('@/components/dripPod/proRecipe/StepList.vue'),

    DpProRecipeBalloon: () =>
      import('@/components/dripPod/proRecipe/Balloon.vue'),

    DpProRecipeProInformation: () =>
      import('@/components/dripPod/proRecipe/ProInformation.vue'),

    DpProRecipeTaste: () => import('@/components/dripPod/proRecipe/Taste.vue')
  },

  setup: (props, context) => {
    const { isPublishPeriod } = usePublishable();
    const { isAndroid } = useUserAgent();
    const setHeaderTitle = inject('setHeaderTitle');
    const proRecipe = ref(undefined);
    const proInformation = ref(null);
    const proRecipeTitle = computed(() => proRecipe.value?.name || '');
    const isLoading = ref(false);
    const isExtracted = ref(false);

    const image = computed(() => {
      if (
        !proRecipe.value?.image_url &&
        !proRecipe.value?.additional_images &&
        !proRecipe.value?.additional_images.length
      ) {
        onImgLoaded();
        return;
      }
      const filteredAdditionalImages =
        proRecipe.value?.additional_images &&
        proRecipe.value?.additional_images.length
          ? proRecipe.value?.additional_images.map((item) => item.image_url)
          : [];
      return [proRecipe.value?.image_url, ...filteredAdditionalImages].filter(
        (item) => !!item
      )[0];
    });

    const proRecipeId = computed(
      () => Number(context.root.$route.params.proRecipeId) || ''
    );

    /* eslint-disable indent */
    const capsuleEcUrl = computed(() =>
      proRecipe.value?.capsule.link_url
        ? context.root.$customUrlScheme.page(
            'ec',
            `url=${proRecipe.value?.capsule.link_url}`
          )
        : false
    );
    /* eslint-enable */

    const onImgLoaded = () => {
      isLoading.value = false;
    };

    const fetchRecipeData = async () => {
      try {
        context.root.$_loading_start();
        const { data } = await context.root
          .$repositories('dpProRecipe')
          .getById(proRecipeId.value);

        proRecipe.value = data;
        proInformation.value = data?.barista;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      }
    };

    /**
     * 抽出中にエラーが発生した場合、ネイティブ側から呼び出される
     * 元のプロレシピIDに紐づくプロレシピ詳細へ遷移する
     */
    const setBrewError = () => {
      context.root.$router.push({
        name: 'DripPodProRecipeDetail',
        params: { proRecipeId: proRecipeId.value }
      });
    };

    /**
     * 抽出が終わったかのフラグを更新する
     */
    const setBrewCompleted = () => {
      isExtracted.value = true;
    };

    /**
     * 抽出が終わって詳細画面に遷移するタイミングでネイティブに通知する
     */
    const onClickLink = () => {
      setHideBrewingView();
      window.localStorage.setItem('BackFromBrewingPage', 'true');
      context.root.$router.push({
        name: 'DripPodProRecipeDetail',
        params: { proRecipeId: proRecipeId.value }
      });
    };

    watch(proRecipe, () => {
      if (proRecipe.value) setHeaderTitle(proRecipeTitle.value);
    });

    watch(isLoading, () => {
      if (!isLoading.value) context.root.$_loading_stop();
      else context.root.$_loading_start();
    });

    // NOTE: ネイティブ側からインスタンスにアクセスするためにwindowにバインドしておく
    window.view = { setBrewError, setBrewCompleted };

    isLoading.value = true;
    fetchRecipeData();

    return {
      WORDS,
      proRecipe,
      proRecipeId,
      image,
      proInformation,
      capsuleEcUrl,
      isLoading,
      isPublishPeriod,
      onImgLoaded,
      isAndroid,
      isExtracted,
      onClickLink
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-pro-recipe-brewing {
  @include mixin.dpDefaultText;

  position: relative;
  padding-bottom: 240px;
}

.dp-pro-recipe-brewing-head {
  padding: 24px 16px 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid variables.$dpGreyEa;

  :last-child {
    margin-bottom: 0;
  }

  &__image {
    @include mixin.imageAspectRatio(200, 200);

    margin: 0 auto 16px;
    width: 53.33vw;
    min-width: 200px;

    img {
      border-radius: 4px;
    }
  }

  &__title {
    margin-bottom: 16px;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.25;
    text-align: center;
  }
}

.dp-pro-recipe-brewing-link {
  position: absolute;
  inset: 0;
  -webkit-tap-highlight-color: transparent;

  // NOTE: リンクのテキストを画面上に表示させないためのスタイル
  // https://www.w3.org/WAI/WCAG22/Techniques/css/C7
  &__text {
    position: absolute;
    width: 1px;
    height: 1px;
    clip-path: inset(100%);
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
